<template>
    <figure
        v-if="imageUrl"
        class="image-single"
    >
        <img
            class="image-single__image"
            :style="style"
            :src="imageUrl"
            :sizes="sizes"
            :srcset="srcset"
            :title="imageTitle ? imageTitle : null"
            :alt="imageAlt"
            :loading="loading"
            :width="width"
            :height="height"
            :decoding="decoding"
        >
        <figcaption v-if="caption">{{ caption }}</figcaption>
    </figure>
</template>

<script>
// You can also choose to add the lazy-image component instead of the src-set
export default {
    props: {
        caption: {
            type: String,
            required: false,
            default: ''
        },
        imageUrl: {
            type: String || null,
            required: true,
            default: ''
        },
        srcset: {
            type: String,
            required: false,
            default: ''
        },
        sizes: {
            type: String,
            required: false,
            default: '100vw'
        },
        imageTitle: {
            type: String,
            required: false,
            default: ''
        },
        imageAlt: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        },
        decoding: {
            type: String,
            default: 'async'
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null
        },
        hasFocalPoint: {
            type: Boolean,
            default: false
        },
        focalPoint: {
            type: Array,
            default() { return [0.5, 0.5]; }
        }
    },
    computed: {
        objectFitPositionX() {
            return (this.focalPoint?.[0] ?? 0) * 100;
        },
        objectFitPositionY() {
            return (this.focalPoint?.[1] ?? 0) * 100;
        },
        objectFitPosition() {
            return `${this.objectFitPositionX}% ${this.objectFitPositionY}%`;
        },
        style() {
            return {
                'object-position': this.hasFocalPoint ? this.objectFitPosition : null
            };
        }
    }
};
</script>

<style lang="less" src="./ImageSingle.less"></style>
