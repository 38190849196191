<template>
    <ImageSingle
        v-bind="$attrs"
        :image-url="src"
        :width="width"
        :height="height"
        :srcset="srcset"
        :sizes="sizes"
        :image-alt="alt"
        :has-focal-point="hasFocalPoint"
        :focal-point="focalPoint"
        :caption="caption"
    />
</template>

<script>
export default {
    props: {
        image: {
            type: Object,
            default() { return null; }
        },
        sizes: {
            type: String,
            default: '(min-width: 768px) 1020px, 100vw'
        },
        alt: {
            type: String,
            required: false,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        }
    },
    computed: {
        src() {
            return this.image?.url ?? null;
        },
        height() {
            return this.image?.height ?? null;
        },
        width() {
            return this.image?.width ?? null;
        },
        srcset() {
            return Object.entries(this.image).map(([key, value]) => {
                const matches = key.match(/w([\d]+)/);

                if (value && matches && matches[1]) {
                    return `${value} ${matches[1]}w`;
                }

                return false;
            })
                .filter(v => v)
                .join(',');
        },
        hasFocalPoint() {
            return this.image?.hasFocalPoint ?? false;
        },
        focalPoint() {
            return this.image?.focalPoint ?? null;
        }
    }
};
</script>
